import { getTypography, getFontSet, screenMin, spaceMb, spaceDt } from '@helpers/styles';
import styled from 'styled-components';

const ContactFormTextBase = styled.h3`
  margin: 0;
`;
const ContactFormTitle = styled(ContactFormTextBase)`
  margin-bottom: ${spaceMb(4.5)};
  ${getTypography('heading-3')};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(4.5)};
  }
`;
const ContactFormText = styled(ContactFormTextBase)`
  margin-right: ${spaceMb(1)};
  white-space: nowrap;

  ${getFontSet('heading-4')};

  ${screenMin('lg')} {
    ${getFontSet('heading-3', 'desktop')};
    margin-right: ${spaceDt(4)};
  }
`;
const ContactFormErrorText = styled(ContactFormTextBase)`
  position: absolute;
  top: 100%;
  margin-top: ${spaceMb(0.5)};
  min-width: fit-content;
  ${getTypography('body-2')};

  ${screenMin('lg')} {
    position: relative;
    top: unset;
    margin-top: unset;
  }
`;

export { ContactFormTextBase, ContactFormTitle, ContactFormText, ContactFormErrorText };
