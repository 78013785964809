import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStateScreenMobile } from '@helpers/hooks';
import {
  ContactFormCheckboxContainer,
  ContactFormCheckboxStyled,
  ContactFormCheckboxText,
  ContactFormCheckboxSec,
  ContactFormCheckboxPane,
  ContactFormCheckboxErrorPane,
  ContactFromReadMoreButton,
  ContactFormCheckboxTextPane,
} from './ContactFormCheckbox.styled';

const ContactFormCheckbox = ({ fieldName, text, currentFieldData, setNewMessageData, errorMessage }) => {
  const [checkState, setCheckState] = useState(currentFieldData);
  const [textCollapsed, setTextCollapsed] = useState(true);

  const clickCheckBox = () => {
    if (checkState === false) {
      setCheckState(true);
      setNewMessageData((prev) => ({ ...prev, [fieldName.toLowerCase()]: true }));
    } else {
      setCheckState(false);
      setNewMessageData((prev) => ({ ...prev, [fieldName.toLowerCase()]: false }));
    }
  };

  const clickCheckText = () => {
    setTextCollapsed(!textCollapsed);
  };

  return (
    <ContactFormCheckboxContainer>
      <ContactFormCheckboxPane>
        <ContactFormCheckboxSec onClick={clickCheckBox}>
          <ContactFormCheckboxStyled name={checkState ? 'checkbox-checked' : 'checkbox-unchecked'} />
        </ContactFormCheckboxSec>
        <ContactFormCheckboxTextPane onClick={useStateScreenMobile() ? clickCheckText : null}>
          <ContactFormCheckboxText collapsed={useStateScreenMobile() ? textCollapsed : false}>
            {text}
          </ContactFormCheckboxText>
          {useStateScreenMobile() && textCollapsed && <ContactFromReadMoreButton>read more</ContactFromReadMoreButton>}
        </ContactFormCheckboxTextPane>
      </ContactFormCheckboxPane>
      {errorMessage && <ContactFormCheckboxErrorPane>{errorMessage}</ContactFormCheckboxErrorPane>}
    </ContactFormCheckboxContainer>
  );
};

ContactFormCheckbox.propTypes = {
  fieldName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  setNewMessageData: PropTypes.func.isRequired,
  currentFieldData: PropTypes.bool,
  errorMessage: PropTypes.string,
};

ContactFormCheckbox.defaultProps = {
  errorMessage: '',
  currentFieldData: false,
};

export default ContactFormCheckbox;
