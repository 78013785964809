import { MAXIMUM_FILE_SIZE_LIMIT } from '../constants';

export const fileEncoder = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const getMailjetReadyAttachments = async (fileArray) => {
  const encodedFiles = await Promise.all(fileArray.map((file) => fileEncoder(file)));

  return fileArray.map((attachment, index) => {
    return {
      contentType: attachment.type,
      fileName: attachment.name,
      size: attachment.size,
      base64Content: encodedFiles[index],
    };
  });
};

export const spaceForFile = (fileSize, listSize) =>
  listSize < MAXIMUM_FILE_SIZE_LIMIT && MAXIMUM_FILE_SIZE_LIMIT - listSize >= fileSize;

export const isFileDuplicated = ({ name, size, type }, list) =>
  !!list?.find((file) => file.fileName === name && file.size === size && file.contentType === type);

export const getAttachmentsTotalSize = (fileArray) =>
  fileArray ? fileArray.reduce((totalSize, attachment) => totalSize + attachment.size, 0) : 0;
