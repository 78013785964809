import { getFontSet, screenMin, color } from '@helpers/styles';
import styled from 'styled-components';
import { ContactFormInputFieldContainerBase } from '../ContactForm.styled';

const ContactFormTextarea = styled.textarea`
  width: 100%;
  border-width: 0;
  outline: none;
  box-shadow: none;
  resize: none;
  color: ${(props) => (props.displayError ? color.text.danger : color.text.primary)};
  background-color: transparent;

  ${getFontSet('body-2')};

  ${screenMin('lg')} {
    ${getFontSet('heading-3', 'desktop')};
  }
`;

const ContactFormMultilineContainer = styled(ContactFormInputFieldContainerBase)`
  align-items: baseline;
`;

export { ContactFormTextarea, ContactFormMultilineContainer };
