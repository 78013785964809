import PropTypes from 'prop-types';
import React from 'react';
import { ContactFormErrorText, ContactFormText } from './ContactFormText.styled';
import { ContactFormInput, ContactFormTextInputContainer } from './ContactFormTextInput.styled';
import { InputCorrectBadge } from '../ContactForm.styled';
import { useCorrectBadge } from '../hooks';

const ContactFormTextInput = React.memo(({ fieldName, currentFieldData, setNewMessageData, errorMessage }) => {
  const showCorrectBadge = useCorrectBadge({ currentFieldData, errorMessage });
  const inputHandler = ({
    nativeEvent: {
      target: { value },
    },
  }) => {
    setNewMessageData((prev) => ({ ...prev, [fieldName.toLowerCase()]: value }));
  };

  return (
    <ContactFormTextInputContainer displayError={errorMessage}>
      <ContactFormText>{fieldName}:</ContactFormText>
      <ContactFormInput onChange={inputHandler} onBlur={inputHandler} value={currentFieldData} />
      {errorMessage && <ContactFormErrorText>{errorMessage}</ContactFormErrorText>}
      {showCorrectBadge && <InputCorrectBadge id="icon-check-oval" />}
    </ContactFormTextInputContainer>
  );
});

ContactFormTextInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  currentFieldData: PropTypes.string,
  setNewMessageData: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

ContactFormTextInput.defaultProps = {
  errorMessage: '',
  currentFieldData: '',
};

ContactFormTextInput.displayName = 'ContactFormTextInput';

export default ContactFormTextInput;
