import { color } from '@helpers/styles';
import styled from 'styled-components';
import {
  ContactFormPickerSelectListItemMobile,
  ContactFormPickerSelectListMobile,
} from './ContactFormPickerListMobile.styled';

const ContactFormPickerSelectListDesktop = styled(ContactFormPickerSelectListMobile)`
  position: absolute;
  top: 100%;
  padding: 0;
  cursor: default;
`;

const ContactFormPickerSelectListItemDesktop = styled(ContactFormPickerSelectListItemMobile)`
  max-width: fit-content;
`;
const ContactFormPickerSelectListOnHoverWrapperDesktop = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  outline: none;

  &:hover,
  &:focus {
    & div {
      background-color: ${color.text.primary};
      color: ${color.text.light};
    }
  }
`;

export {
  ContactFormPickerSelectListDesktop,
  ContactFormPickerSelectListItemDesktop,
  ContactFormPickerSelectListOnHoverWrapperDesktop,
};
