import { useEffect, useState } from 'react';

export const useCorrectBadge = ({ currentFieldData, errorMessage }) => {
  const [showCorrectBadge, setShowCorrectBadge] = useState(false);

  useEffect(() => {
    setShowCorrectBadge(currentFieldData.length > 0 && !errorMessage);
  }, [errorMessage, currentFieldData]);

  return showCorrectBadge;
};
