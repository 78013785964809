import React from 'react';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import {
  ContactFormPickerSelectListMobile,
  ContactFormPickerSelectListItemMobile,
  ContactFormPickerSelectListModalMobile,
} from './ContactFormPickerListMobile.styled';

const optionsChanged = (prevProps, nextProps) => isEqual(prevProps.options, nextProps.options);

const ContactFormPickerListMobile = React.memo(({ options, selectNewOption }) => {
  const subjectList = options.map((option) => (
    <ContactFormPickerSelectListItemMobile
      key={option}
      onMouseDown={() => {
        selectNewOption(option);
      }}
    >
      {option}
    </ContactFormPickerSelectListItemMobile>
  ));

  return (
    <ContactFormPickerSelectListModalMobile>
      <ContactFormPickerSelectListMobile>{subjectList}</ContactFormPickerSelectListMobile>
    </ContactFormPickerSelectListModalMobile>
  );
}, optionsChanged);

ContactFormPickerListMobile.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectNewOption: PropTypes.func.isRequired,
};

export default ContactFormPickerListMobile;
