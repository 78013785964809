// import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import _isEmpty from 'lodash.isempty';
import { signal } from '@helpers/methods';
import { FORM_STATE } from '../constants';
import { obtainCaptchaToken } from './recaptcha';
import { getAllErrorMessages } from '../utils';

const submitMessage = async (messageObj, token) => {
  try {
    const response = await fetch(process.env.GATSBY_URL_WORKER_CONTACT_FORM, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'g-captcha-token': token },
      body: JSON.stringify(messageObj),
    });

    return response;
  } catch (error) {
    signal(`Submit error: ${error}`);
    return error;
  }
};

export const formSendMessage = async (recaptchaCb, data, setState, onSendSuccess) => {
  // Validates ALL input fields, no matter if user touched it or not

  try {
    setState(FORM_STATE.VALIDATE);
    const token = await obtainCaptchaToken(recaptchaCb);

    setState(FORM_STATE.LOADING);
    const response = await submitMessage(data, token);

    signal(await response.json());

    if (!response.ok) throw new Error('Message sending failed.');

    setState(FORM_STATE.SUCCESS);

    onSendSuccess(data.subject);
  } catch (error) {
    signal(error);
    setState(FORM_STATE.ERROR);
  }
};

export const formValidate = (data, config, setErrors, setState) => {
  const errorMessagesObj = getAllErrorMessages(data, config);

  setErrors(errorMessagesObj);

  if (!_isEmpty(errorMessagesObj)) {
    setState(FORM_STATE.FIX);
    return false;
  }

  if (_isEmpty(data) && _isEmpty(errorMessagesObj)) {
    setState(FORM_STATE.EMPTY);
    return false;
  }

  return true;
};
