import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { breakpoints } from '@helpers/styles';
import { useWindowSize } from '@helpers/hooks';
import { ContactFormPickerContainer, ContactFormPickerItemGrouper, DropdownArrow } from './ContactFormPicker.styled';
import ContactFormPickerListDesktop from './ContactFormPickerListDesktop';
import ContactFormPickerListMobile from './ContactFormPickerListMobile';
import { ContactFormErrorText, ContactFormText } from './ContactFormText.styled';

const ContactFormPicker = ({ fieldName, options, currentFieldData, setNewMessageData, errorMessage }) => {
  const [isVisible, setIsVisible] = useState(false);

  const windowSize = useWindowSize();

  const selectNewOption = (option) => {
    setNewMessageData((prev) => ({ ...prev, [fieldName.toLowerCase()]: option }));
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleFocus = () => {
    setIsVisible(true);
  };

  return (
    <ContactFormPickerContainer
      tabIndex="0"
      onFocus={!isMobile ? handleFocus : null}
      onMouseDown={handleClick}
      displayError={errorMessage}
    >
      <ContactFormPickerItemGrouper>
        <ContactFormText>{fieldName}:</ContactFormText>
        <ContactFormText>{currentFieldData}</ContactFormText>
      </ContactFormPickerItemGrouper>

      {isVisible &&
        (isMobile ? (
          <ContactFormPickerListMobile options={options} selectNewOption={selectNewOption} />
        ) : (
          <ContactFormPickerListDesktop
            setParentVisible={setIsVisible}
            options={options}
            selectNewOption={selectNewOption}
          />
        ))}
      <ContactFormPickerItemGrouper>
        <DropdownArrow rotate={isVisible} name="icon-chevron" displayError={errorMessage} />
        {errorMessage && windowSize.width >= breakpoints.lg && (
          <ContactFormErrorText>{errorMessage}</ContactFormErrorText>
        )}
      </ContactFormPickerItemGrouper>
      {windowSize.width < breakpoints.lg && <ContactFormErrorText>{errorMessage}</ContactFormErrorText>}
    </ContactFormPickerContainer>
  );
};

ContactFormPicker.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  setNewMessageData: PropTypes.func.isRequired,
  currentFieldData: PropTypes.string,
  errorMessage: PropTypes.string,
};

ContactFormPicker.defaultProps = {
  errorMessage: '',
  currentFieldData: '',
};

export default ContactFormPicker;
