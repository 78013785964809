import React from 'react';
import { screenMin, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import styled from 'styled-components';
import { Vector } from '@fragments';
import { ContactFormInputFieldContainerBase } from '../ContactForm.styled';

const ContactFormPickerContainer = styled(ContactFormInputFieldContainerBase)`
  position: relative;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  outline: none;
`;

const ContactFormPickerItemGrouper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
`;

const DropdownArrow = styled((props) => <Vector {...props} />)`
  right: ${toVW(4, 'mobile')};
  width: ${toVW(16, 'mobile')};
  height: ${toVW(8, 'mobile')};
  fill: ${color.text.primary};
  transition: 0.2s;

  transform: ${(props) => `rotate(${props.rotate ? '180' : '0'}deg)`};
  fill: ${(props) => (props.displayError ? color.text.danger : color.text.primary)};

  ${screenMin('lg')} {
    right: ${toVW(4, 'desktop')};
    width: ${toVW(40, 'desktop')};
    height: ${toVW(20, 'desktop')};
  }
`;

export { ContactFormPickerContainer, DropdownArrow, ContactFormPickerItemGrouper };
