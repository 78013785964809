import { spaceMb, spaceDt, screenMin, screenMax, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import styled, { css } from 'styled-components';
import { Button } from '@fragments';

export const ContactFormButtonStyled = styled(Button)`
  margin-left: ${spaceMb(1)};
  transition-property: background-color, border-color;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  margin-bottom: ${spaceMb(2)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ formState }) => {
    switch (formState) {
      case 'error':
        return css`
          background-color: ${color.bg.danger} !important; /* Overwrite disabled styles */
          border-color: ${color.bg.danger} !important;
          color: ${color.text.light};
        `;
      case 'loading':
        return css`
          background-color: ${color.bg.secondary} !important;
          border-color: ${color.bg.secondary} !important;
          color: ${color.text.light};
        `;
      default:
        return css`
          background-color: ${color.bg.primary} !important;
          border-color: ${color.bg.primary} !important;
          color: ${color.text.light};
        `;
    }
  }}
  ${screenMin('lg')} {
    margin-left: ${spaceDt(1)};
    margin-bottom: ${spaceDt(2)};
    width: ${toVW(280, 'desktop')};
    height: ${toVW(50, 'desktop')};
  }
  ${screenMax('lg')} {
    width: ${toVW(160, 'mobile')};
    height: ${toVW(35, 'mobile')};
    padding-left: ${spaceMb(1)};
    padding-right: ${spaceMb(1)};
  }
`;
