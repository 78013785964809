import { signal } from '@helpers/methods';

export const obtainCaptchaToken = async (callback) => {
  if (!callback) throw new Error('reCaptcha requires a callback to work properly!');

  try {
    const token = await callback('contact_form');
    return token;
  } catch (error) {
    signal(error);
    return null;
  }
};
