import * as yup from 'yup';
import { VALIDATION_TYPES } from '../constants';

const validate = (field, validationType) => {
  let valid = true;
  switch (validationType) {
    case VALIDATION_TYPES.checked:
      valid = field;
      return { status: valid, message: valid ? '' : 'This field is required' };
    case VALIDATION_TYPES.required:
      valid = field && field.length > 0;
      return { status: valid, message: valid ? '' : 'This field is required' };
    case VALIDATION_TYPES.email:
      valid = field && field.length > 0;
      if (!valid) return { status: valid, message: valid ? '' : 'This field is required' };
      valid = yup.string().email().isValidSync(field);
      return { status: valid, message: valid ? '' : 'Invalid email' };
    case VALIDATION_TYPES.attached:
      // Validation performed by the react-dropzone plugin
      return { status: true, message: '' };
    default:
      return { status: false, message: 'Could not find validateType property' };
  }
};

// Should be called after onChange or onBlur
// FIXME: Refacto the logic, it's error prone
export const getRuntimeErrorMessages = (newMessageData, fieldsConfig) => {
  const errorMessages = {};
  Object.keys(newMessageData).forEach((fieldName) => {
    if (newMessageData[fieldName] !== undefined) {
      const validationResponse = validate(
        newMessageData[fieldName],
        fieldsConfig.find((fieldConfig) => fieldConfig.label === fieldName.toLowerCase()).validationType
      );

      if (!validationResponse.status) {
        errorMessages[fieldName] = validationResponse.message;
      }
    }
  });
  return errorMessages;
};

// Should be called before submitting the form
export const getAllErrorMessages = (newMessageData, fieldsConfig) => {
  const errorMessages = {};
  fieldsConfig.forEach((fieldConfig) => {
    const validateResponse = validate(newMessageData[fieldConfig.label], fieldConfig.validationType);
    if (!validateResponse.status) {
      errorMessages[fieldConfig.label] = validateResponse.message;
    }
  });

  return errorMessages;
};
