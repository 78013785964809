import React, { forwardRef, useImperativeHandle, useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import styled from 'styled-components';
import { color, spaceMb, spaceDt, screenMax, getTypography } from '@helpers/styles';
import { MAXIMUM_FILE_SIZE_LIMIT, FILE_ERROR_MESSAGE } from '../constants';
import { getMailjetReadyAttachments, spaceForFile, isFileDuplicated, getAttachmentsTotalSize } from '../utils';

import Attachment from './Attachment';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMax('lg')} {
    margin-top: ${spaceMb(4)};
  }
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color.text.danger};
  margin-bottom: ${spaceDt(2)};

  ${getTypography('body-1')};

  ${screenMax('lg')} {
    margin-bottom: ${spaceDt(2)};
    ${getTypography('heading-4')};
  }
`;

const Error = styled.p`
  margin-bottom: 0;
`;

const AttachmentsList = forwardRef(({ files, setState }, ref) => {
  const [rejectionsList, setRejectionsList] = useState([]);
  const currentListSize = useMemo(() => getAttachmentsTotalSize(files), [files]);

  const validator = (file) => {
    let allIssues = [];

    if (!spaceForFile(file.size, currentListSize)) {
      allIssues = [
        ...allIssues,
        {
          code: 'total-size-too-large',
          message: FILE_ERROR_MESSAGE.SIZE_LIMIT,
        },
      ];
    }

    if (isFileDuplicated(file, files)) {
      allIssues = [
        ...allIssues,
        {
          code: 'file-exists',
          message: FILE_ERROR_MESSAGE.DUPLICATE,
        },
      ];
    }

    return allIssues.length > 0 ? allIssues : null;
  };

  const isMatchingFile = (file, name, size) => file.fileName !== name && file.size !== size;

  const onDrop = async (acceptedFiles, fileRejections) => {
    const apiReadyAttachments = await getMailjetReadyAttachments(acceptedFiles);

    setRejectionsList(fileRejections);

    setState((prev) => {
      const fileAttachments = Array.isArray(prev.fileAttachments)
        ? [...prev.fileAttachments, ...apiReadyAttachments]
        : apiReadyAttachments;
      return { ...prev, fileAttachments };
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: '.jpeg, .png, .jpg, .doc, .docx, .odt, .odp, .ods, .pdf, .ppt, .pptx',
    maxSize: MAXIMUM_FILE_SIZE_LIMIT,
    onDrop,
    validator,
  });

  useImperativeHandle(ref, () => ({
    handleAddFile() {
      open();
    },
  }));

  const handleRemove = (name, size) => {
    setState((prev) => ({
      ...prev,
      fileAttachments: files.filter((file) => isMatchingFile(file, name, size)),
    }));

    setRejectionsList([]);
  };

  const memoFileList = useMemo(
    () =>
      files?.map(({ fileName, size }) => (
        <Attachment key={uniqid()} fileName={fileName} handleRemove={() => handleRemove(fileName, size)} />
      )),
    [files]
  );

  useEffect(() => {
    if (!files.length) setRejectionsList([]);
  }, [files]);

  useEffect(() => {
    const clearRejectionsList = () =>
      setTimeout(() => {
        setRejectionsList([]);
      }, 3000);

    if (rejectionsList.length) clearRejectionsList();

    return () => {
      clearTimeout(clearRejectionsList);
    };
  }, [rejectionsList]);

  return (
    <Container>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
      </div>

      {memoFileList}

      <ErrorsContainer>
        {Boolean(rejectionsList.length) &&
          rejectionsList.map(({ file, errors }) =>
            errors.map((error) => (
              <Error key={uniqid()}>
                {`"${file.name}"`}: {error.message}
              </Error>
            ))
          )}
      </ErrorsContainer>
    </Container>
  );
});

export default AttachmentsList;

AttachmentsList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      size: PropTypes.number,
      base64Content: PropTypes.string,
    })
  ),
  setState: PropTypes.func.isRequired,
};

AttachmentsList.defaultProps = {
  files: [],
};
