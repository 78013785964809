import { color, getFontSet, screenMin, spaceMb, spaceDt } from '@helpers/styles';
import styled from 'styled-components';

const ContactFormPickerSelectListModalMobile = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${spaceMb(1.5)};
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  ${screenMin('lg')} {
    padding: 0 ${spaceDt(1.5)};
  }
`;

const ContactFormPickerSelectListMobile = styled.div`
  display: flex;
  padding: ${spaceMb(1.5)} 0;
  flex-direction: column;
  background-color: ${color.accent['1']};
  width: 100%;
  z-index: 3;

  ${screenMin('lg')} {
    padding: 0 ${spaceDt(1.5)};
  }
`;

const ContactFormPickerSelectListItemMobile = styled.div`
  padding: ${spaceMb(1.5)} ${spaceMb(6)} ${spaceMb(1.5)} ${spaceMb(1.5)};
  color: ${color.text.primary};

  ${getFontSet('heading-4')}

  ${screenMin('lg')} {
    ${getFontSet('heading-3', 'desktop')}
    padding: ${spaceDt(0.5)} ${spaceDt(3.5)};
  }

  &:hover,
  &:focus {
    outline: none;
    background-color: ${color.text.primary};
    color: ${color.text.light};
  }
`;

export {
  ContactFormPickerSelectListMobile,
  ContactFormPickerSelectListItemMobile,
  ContactFormPickerSelectListModalMobile,
};
