export const VALIDATION_TYPES = {
  required: 'REQUIRED',
  email: 'EMAIL',
  checked: 'CHECKED',
  attached: 'ATTACHED',
};

export const INPUT_TYPES = {
  text: 'TEXT',
  multiline: 'MULTILINE',
  picker: 'PICKER',
  checkbox: 'CHECKBOX',
};

export const FIELD_CONFIG = [
  { label: 'name', type: INPUT_TYPES.text, validationType: VALIDATION_TYPES.required },
  { label: 'email', type: INPUT_TYPES.text, validationType: VALIDATION_TYPES.email },
  {
    label: 'subject',
    type: INPUT_TYPES.picker,
    validationType: VALIDATION_TYPES.required,
    options: ['Funding', 'Software Development', 'Career', 'Hello :)'],
  },
  { label: 'message', type: INPUT_TYPES.multiline, validationType: VALIDATION_TYPES.required },
  { label: 'fileattachments', type: INPUT_TYPES.upload, validationType: VALIDATION_TYPES.attached },
];

export const SUBMIT_LABEL = {
  IDLE: 'Write to us',
  VALIDATE: 'Validating',
  LOADING: 'Sending',
  ERROR: 'Failed',
  EMPTY: 'Empty data',
  FIX: 'Invalid data',
  SUCCESS: 'Success!',
};

export const ADD_FILE_LABEL = 'Add file';

export const FORM_STATE = {
  IDLE: 'idle',
  VALIDATE: 'validate',
  LOADING: 'loading',
  ERROR: 'error',
  EMPTY: 'empty',
  FIX: 'fix',
  SUCCESS: 'success',
};

export const FILE_ERROR_MESSAGE = {
  DUPLICATE: 'The following file is already included!',
  SIZE_LIMIT: 'All included files should not take more than 15 MB.',
};

export const MAXIMUM_FILE_SIZE_LIMIT = 15728640;
