import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import _throttle from 'lodash.throttle';
import _flow from 'lodash.flow';
import { TYPE_COMPONENT } from '@helpers/types';
import { signal } from '@helpers/methods';
import { GATSBY_COOKIE_SOURCE_TRACKING } from '@constants';
import Delay from '@components/Delay';
import { getPipedriveBody, submitLeadDetails } from './services/pipedrive';
import { formSendMessage, formValidate } from './services';
import { getRuntimeErrorMessages } from './utils';
import { FIELD_CONFIG, SUBMIT_LABEL, FORM_STATE, INPUT_TYPES, ADD_FILE_LABEL } from './constants';

import {
  Spinner,
  Success,
  ContactFormContainer,
  ContactFormRecaptchaInfo,
  ContactFormFooter,
  ContactFormButtons,
} from './ContactForm.styled';

import ContactFormFieldsFactory from './components/ContactFormFieldsFactory';
import { ContactFormButtonStyled } from './components/ContactFormButton.styled';
import AttachmentsList from './components/AttachmentsList';

const Buttons = ({ state, delayed, handleSubmit, handleAddFile }) => (
  <>
    <ContactFormButtonStyled formState={state} onClick={handleSubmit} disabled={state !== FORM_STATE.IDLE}>
      {SUBMIT_LABEL[state.toUpperCase()]}
      {state === FORM_STATE.LOADING && (delayed ? <Success id="icon-success" /> : <Spinner />)}
    </ContactFormButtonStyled>
    <ContactFormButtonStyled formState={state} onClick={handleAddFile} disabled={state !== FORM_STATE.IDLE}>
      {ADD_FILE_LABEL}
    </ContactFormButtonStyled>
  </>
);

Buttons.propTypes = {
  state: PropTypes.string.isRequired,
  delayed: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleAddFile: PropTypes.func.isRequired,
};

Buttons.defaultProps = {
  delayed: false,
};

const gtmFormSubmissionEvent = (subject) => {
  if (window.dataLayer === 'undefined' || typeof window.dataLayer !== 'object')
    return signal('Data layer not available.');

  window.dataLayer.push({ event: '_event', category: 'Contact Form', action: 'Message Sent', label: subject });
  return null;
};

const ContactForm = ({ headline, fieldsConfig, defaultSubject }) => {
  // newMessageData is an object that contains all the data created by user input
  // Object keys are the same as names in provided fieldsConfig
  // Example:
  /**
   * {
   *  Name: "Hans Schwarzmuller",
   *  E-mail: "contact@importantcorp.com",
   *  Topic: "Work",
   *  Message: "Example message"
   * }
   */
  const [cookies] = useCookies([GATSBY_COOKIE_SOURCE_TRACKING]);
  const [newMessageData, setNewMessageData] = useState(defaultSubject ? { subject: defaultSubject } : {});
  const [currentFormState, setCurrentFormState] = useState(FORM_STATE.IDLE);
  const [errorMessages, setErrorMessages] = useState({});
  const attachmentsListRef = useRef();

  const onSendSuccess = (subject) => {
    signal('on send success running....');
    gtmFormSubmissionEvent(subject);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const addAuthorToCookie = (cookieObj) => {
    return {
      message: newMessageData,
      visits: { ...cookieObj },
    };
  };

  const formSubmitTrackingInfo = _flow([addAuthorToCookie, getPipedriveBody, submitLeadDetails]);

  const handleSubmit = _throttle(
    async () => {
      if (currentFormState !== FORM_STATE.IDLE) return;

      formValidate(newMessageData, fieldsConfig, setErrorMessages, setCurrentFormState) &&
        formSendMessage(executeRecaptcha, newMessageData, setCurrentFormState, onSendSuccess);
    },
    1000,
    true
  );

  const handleAddFile = () => attachmentsListRef.current.handleAddFile();

  useEffect(() => {
    // Validates all data already provided by user
    setErrorMessages(getRuntimeErrorMessages(newMessageData, fieldsConfig));
  }, [newMessageData]);

  // TODO: Refactor, to many nested if's. Reading is diffucult.
  useEffect(() => {
    const formInitialState = () =>
      setTimeout(() => {
        setCurrentFormState(FORM_STATE.IDLE);
      }, 2000);

    const formReset = () =>
      setTimeout(() => {
        setNewMessageData({});
      }, 1000);

    if (currentFormState === FORM_STATE.SUCCESS) {
      newMessageData.subject !== 'Career' && formSubmitTrackingInfo(cookies[GATSBY_COOKIE_SOURCE_TRACKING]);
      formReset();
    }

    if (
      currentFormState === FORM_STATE.SUCCESS ||
      currentFormState === FORM_STATE.ERROR ||
      currentFormState === FORM_STATE.EMPTY ||
      currentFormState === FORM_STATE.FIX
    )
      formInitialState();

    return () => {
      clearTimeout(formInitialState);
      clearTimeout(formReset);
    };
  }, [currentFormState]);

  return (
    <ContactFormContainer id="the-contact-form">
      {headline}

      <ContactFormFieldsFactory
        fieldsConfig={fieldsConfig}
        currentMessageData={newMessageData}
        setNewMessageData={setNewMessageData}
        errorMessages={errorMessages}
      />

      <ContactFormFooter>
        <ContactFormButtons>
          {currentFormState !== FORM_STATE.SUCCESS ? (
            <Buttons state={currentFormState} handleSubmit={handleSubmit} handleAddFile={handleAddFile} />
          ) : (
            <Delay
              by={1000}
              showUntilSuccess={
                <Buttons state={FORM_STATE.LOADING} handleSubmit={handleSubmit} handleAddFile={handleAddFile} delayed />
              }
            >
              <Buttons state={FORM_STATE.SUCCESS} handleSubmit={handleSubmit} handleAddFile={handleAddFile} />
            </Delay>
          )}
        </ContactFormButtons>

        <AttachmentsList ref={attachmentsListRef} files={newMessageData.fileAttachments} setState={setNewMessageData} />

        {Object.keys(newMessageData).length > 0 && (
          <ContactFormRecaptchaInfo>
            This form is protected by reCaptcha. <br /> Google Privacy Policy and{' '}
            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
              Terms of Service
            </a>{' '}
            apply.
          </ContactFormRecaptchaInfo>
        )}
      </ContactFormFooter>
    </ContactFormContainer>
  );
};

const TYPE_FIELDS_CONFIG = PropTypes.shape({
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.any]),
});

ContactForm.propTypes = {
  fieldsConfig: PropTypes.arrayOf(TYPE_FIELDS_CONFIG),
  headline: PropTypes.oneOfType([TYPE_COMPONENT, PropTypes.string]),
  defaultSubject: PropTypes.string,
};

ContactForm.defaultProps = {
  fieldsConfig: FIELD_CONFIG,
  headline: null,
  defaultSubject: null,
};

export default ContactForm;
