import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Delay = ({ by, showUntilSuccess, children }) => {
  const [delay, setDelay] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setDelay(false), by);
    return () => clearTimeout(timeout);
  }, []);

  return delay ? showUntilSuccess : children;
};

Delay.propTypes = {
  by: PropTypes.number.isRequired,
  showUntilSuccess: PropTypes.node.isRequired,
};

export default Delay;
