import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Vector } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${spaceMb(3.5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(3.5)};
  }
`;

const FileName = styled.div`
  margin-left: ${spaceDt(2)};
  margin-right: ${spaceDt(2)};
  color: ${color.text.primary};

  ${screenMax('lg')} {
    margin-left: ${spaceMb(2)};
    margin-right: ${spaceMb(2)};
  }
  ${getTypography('body-1')};
`;

const IconFile = styled((props) => <Vector {...props} />)`
  width: ${toVW(16, 'mobile')};
  height: ${toVW(38, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(16, 'desktop')};
    height: ${toVW(38, 'desktop')};
  }
`;

const IconRemove = styled((props) => <Vector {...props} />)`
  width: ${toVW(17, 'mobile')};
  height: ${toVW(18, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(17, 'desktop')};
    height: ${toVW(18, 'desktop')};
  }
`;

const RemoveButton = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  background-color: ${color.accent[1]};
  transition: background-color 0.2s ease;

  width: ${toVW(37, 'mobile')};
  height: ${toVW(37, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(37, 'desktop')};
    height: ${toVW(37, 'desktop')};
  }

  &:active {
    background-color: ${color.bg.secondary};

    .icon-remove {
      fill: ${color.text.light};
    }
  }
`;

const Attachment = ({ fileName, handleRemove }) => {
  const memoHandleRemove = useCallback(() => {
    handleRemove();
  }, []);

  return (
    <Container>
      <IconFile name="file-attach" />
      <FileName>{fileName}</FileName>
      <RemoveButton onClick={memoHandleRemove}>
        <IconRemove name="file-remove" className="icon-remove" />
      </RemoveButton>
    </Container>
  );
};

Attachment.propTypes = {
  fileName: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default Attachment;
