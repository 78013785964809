import React from 'react';
import styled from 'styled-components';
import { Vector } from '@fragments';
import { color, spaceMb, spaceDt, getTypography, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const ContactFormCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(8)};
  }

  ${screenMax('lg')} {
    margin-bottom: ${spaceMb(2)};
  }
`;

const ContactFormCheckboxPane = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContactFormCheckboxText = styled.div`
  ${screenMax('lg')} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.collapsed ? 2 : 0)};
    -webkit-box-orient: vertical;
  }
  ${getTypography('body-2')};
`;

const ContactFormCheckboxStyled = styled((props) => <Vector {...props} />)`
  width: ${toVW(16, 'mobile')};
  height: ${toVW(16, 'mobile')};
  max-width: ${toVW(16, 'mobile')};
  max-height: ${toVW(16, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(30, 'desktop')};
    height: ${toVW(30, 'desktop')};
    max-width: ${toVW(30, 'desktop')};
    max-height: ${toVW(30, 'desktop')};
  }
`;

const ContactFormCheckboxSec = styled.div`
  width: ${toVW(16, 'mobile')};
  height: ${toVW(16, 'mobile')};
  ${screenMin('lg')} {
    width: ${toVW(30, 'desktop')};
    height: ${toVW(30, 'desktop')};
  }
`;

const ContactFormCheckboxErrorPane = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${color.text.danger};
`;

const ContactFromReadMoreButton = styled.div`
  text-decoration: underline;
`;

const ContactFormCheckboxTextPane = styled.div`
  margin-left: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-left: ${spaceDt(5)};
  }
`;

export {
  ContactFormCheckboxContainer,
  ContactFormCheckboxStyled,
  ContactFormCheckboxText,
  ContactFormCheckboxSec,
  ContactFormCheckboxPane,
  ContactFormCheckboxErrorPane,
  ContactFromReadMoreButton,
  ContactFormCheckboxTextPane,
};
