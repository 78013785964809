import { getFontSet, screenMin } from '@helpers/styles';
import styled from 'styled-components';
import { ContactFormInputFieldContainerBase } from '../ContactForm.styled';

const ContactFormInput = styled.input`
  width: 100%;
  border-width: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  ${getFontSet('heading-4')};
  ${screenMin('lg')} {
    ${getFontSet('heading-3', 'desktop')};
  }
`;

const ContactFormTextInputContainer = styled(ContactFormInputFieldContainerBase)``;

export { ContactFormInput, ContactFormTextInputContainer };
