import { GATSBY_PIPEDRIVE_ORGANIZATION_ID, GATSBY_PIPEDRIVE_API_URL_LEADS, GATSBY_PIPEDRIVE_API_KEY } from '@constants';
import { signal } from '@helpers/methods';

const simpleFormatter = (obj) =>
  Object.entries(obj)
    .map(([key, value]) => `<li>${key}: ${value}</li>`)
    .join('');

const nestedFormatter = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => {
      const details = simpleFormatter(value);

      return `
      <p>${key}:</p><br>
      <ul>${details}</ul>
    `;
    })
    .join('');
};

export const getPipedriveBody = (obj) => {
  return JSON.stringify({
    title: `${obj?.message?.name ?? 'Website'} lead`,
    organization_id: Number(GATSBY_PIPEDRIVE_ORGANIZATION_ID),
    note: `
      <p><b>Message:<b></p>

      <ul>
        ${simpleFormatter(obj?.message)}
      </ul>

      <br>

      <p><b>Visits:<b></p><br><br>

      ${nestedFormatter(obj?.visits)}`,
  });
};

export const submitLeadDetails = async (strObj) => {
  try {
    const response = await fetch(`${GATSBY_PIPEDRIVE_API_URL_LEADS}?api_token=${GATSBY_PIPEDRIVE_API_KEY}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: strObj,
    });

    const data = await response.json();
    signal(data);
  } catch {
    signal('Submiting pipedrive lead details failed.');
  }
};
