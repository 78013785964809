import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { ContactFormMultilineContainer, ContactFormTextarea } from './ContactFormMultiline.styled';
import { ContactFormErrorText, ContactFormText } from './ContactFormText.styled';
import { useCorrectBadge } from '../hooks';
import { InputCorrectBadge } from '../ContactForm.styled';

const ContactFormMultiline = React.memo(({ fieldName, currentFieldData, setNewMessageData, errorMessage }) => {
  const showCorrectBadge = useCorrectBadge({ currentFieldData, errorMessage });
  const textareaRef = useRef();
  const heightLimit = 400;

  const userInputHandler = (e) => {
    textareaRef.current.style.height = '';
    textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, heightLimit)}px`;
    const text = e.nativeEvent.target.value;
    setNewMessageData((prev) => ({ ...prev, [fieldName.toLowerCase()]: text }));
  };

  return (
    <ContactFormMultilineContainer displayError={errorMessage}>
      <ContactFormText>{fieldName}:</ContactFormText>
      <ContactFormTextarea
        ref={textareaRef}
        rows={1}
        onChange={userInputHandler}
        errorMessage={errorMessage}
        value={currentFieldData}
      />
      {errorMessage && <ContactFormErrorText>{errorMessage}</ContactFormErrorText>}
      {showCorrectBadge && <InputCorrectBadge id="icon-check-oval" />}
    </ContactFormMultilineContainer>
  );
});

ContactFormMultiline.propTypes = {
  fieldName: PropTypes.string.isRequired,
  currentFieldData: PropTypes.string,
  setNewMessageData: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

ContactFormMultiline.defaultProps = {
  errorMessage: '',
  currentFieldData: '',
};

ContactFormMultiline.displayName = 'ContactFormMultiline';

export default ContactFormMultiline;
