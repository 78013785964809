import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ContactFormPickerSelectListOnHoverWrapperDesktop,
  ContactFormPickerSelectListDesktop,
  ContactFormPickerSelectListItemDesktop,
} from './ContactFormPickerListDesktop.styled';

const ContactFormPickerListDesktop = ({ setParentVisible, options, selectNewOption }) => {
  const firstChild = useRef(null);
  const handleKeyPress = (event, option) => {
    if (event.key === 'Enter') {
      selectNewOption(option);
      setParentVisible(false);
    }
  };

  useEffect(() => {
    firstChild.current.focus();
  }, []);

  return (
    <ContactFormPickerSelectListDesktop>
      {options.map((option, index) => (
        <ContactFormPickerSelectListOnHoverWrapperDesktop
          tabIndex="0"
          ref={index === 0 ? firstChild : null}
          key={option}
          onKeyPress={(event) => handleKeyPress(event, option)}
          onMouseDown={() => {
            selectNewOption(option);
          }}
        >
          <ContactFormPickerSelectListItemDesktop>{option}</ContactFormPickerSelectListItemDesktop>
        </ContactFormPickerSelectListOnHoverWrapperDesktop>
      ))}
    </ContactFormPickerSelectListDesktop>
  );
};

ContactFormPickerListDesktop.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectNewOption: PropTypes.func.isRequired,
  setParentVisible: PropTypes.func.isRequired,
};

export default ContactFormPickerListDesktop;
