import { screenMin, spaceMb, spaceDt, color, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import styled, { keyframes } from 'styled-components';
import { IconSuccess, IconCheckOval } from '@icons';
import { Container } from '@fragments';

const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: ${toVW(20, 'mobile')};
  height: ${toVW(20, 'mobile')};
  border-radius: 50%;
  border: 3px solid ${color.bg.lightWithOpacity(0.1)};
  border-top: 3px solid ${color.bg.light};
  border-left: 3px solid ${color.bg.light};
  margin-left: ${spaceMb(1)};
  animation-name: ${spinnerAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  ${screenMin('lg')} {
    width: ${toVW(20, 'desktop')};
    height: ${toVW(20, 'desktop')};
    border-radius: 50%;
    border: 3px solid ${color.bg.lightWithOpacity(0.1)};
    border-top: 3px solid ${color.bg.light};
    border-left: 3px solid ${color.bg.light};
    margin-left: ${spaceDt(1)};
  }
`;

const ContactFormContainer = styled(Container)`
  margin-bottom: ${spaceMb(13.5)};
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(13.5)};
  }
`;

const ContactFormHeader = styled.h3`
  ${getTypography('heading-2')}
  ${screenMin('lg')} {
    display: block;
    margin-bottom: ${spaceDt(7)};
  }
`;

const InputCorrectBadge = styled(IconCheckOval)`
  max-width: ${toVW(29, 'mobile')};
  max-height: ${toVW(29, 'mobile')};
  position: absolute;
  top: ${toVW(2, 'mobile')};
  right: 0;
  ${screenMin('lg')} {
    max-width: ${toVW(29, 'desktop')};
    max-height: ${toVW(29, 'desktop')};
    top: ${spaceDt(1.5)};
  }
`;

const ContactFormInputFieldContainerBase = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${color.bg.primary};
  margin-bottom: ${spaceMb(5)};
  color: ${(props) => (props.displayError ? color.text.danger : color.text.primary)};
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(7)};
  }
`;

const ContactFormRecaptchaInfo = styled.p`
  position: absolute;
  top: calc(100% + ${spaceMb(0.5)});
  left: ${spaceMb(1)};
  ${getTypography('body-5-tight')}
  ${screenMin('lg')} {
    top: calc(100% + ${spaceDt(0.5)});
    left: ${spaceDt(1)};
    margin-top: ${spaceDt(0.5)};
    margin-left: ${spaceDt(1)};
  }
`;

const ContactFormFooter = styled.div`
  position: relative;
`;

const fadeAnimation = keyframes`
  0% { opacity:0; }
  100% { opacity: 1; }
`;

const Success = styled(IconSuccess)`
  margin-left: ${spaceMb(1)};
  max-width: ${toVW(21, 'mobile')};
  max-height: ${toVW(22, 'mobile')};
  animation-name: ${fadeAnimation};
  animation-duration: 0.5s;
  ${screenMin('lg')} {
    margin-left: ${spaceDt(1)};
    max-width: ${toVW(21, 'desktop')};
    max-height: ${toVW(22, 'desktop')};
  }
`;

const ContactFormButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export {
  Spinner,
  Success,
  ContactFormFooter,
  ContactFormRecaptchaInfo,
  InputCorrectBadge,
  ContactFormContainer,
  ContactFormInputFieldContainerBase,
  ContactFormHeader,
  ContactFormButtons,
};
