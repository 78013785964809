import PropTypes from 'prop-types';
import React from 'react';
import { INPUT_TYPES } from '../constants';
import ContactFormMultiline from './ContactFormMultiline';
import ContactFormPicker from './ContactFormPicker';
import ContactFormTextInput from './ContactFormTextInput';
import ContactFormCheckbox from './ContactFormCheckbox';

const capitalize = (string) => string.substring(0, 1).toUpperCase() + string.slice(1);

const getInputComponent = (fieldObj, currentFieldData, setNewMessageData, errorMessages) => {
  const fieldName = capitalize(fieldObj.label);

  switch (fieldObj.type) {
    case INPUT_TYPES.text:
      return (
        <ContactFormTextInput
          key={fieldObj.label}
          fieldName={fieldName}
          currentFieldData={currentFieldData}
          setNewMessageData={setNewMessageData}
          errorMessage={errorMessages[fieldObj.label]}
          validationType={fieldObj.validationType}
        />
      );
    case INPUT_TYPES.multiline:
      return (
        <ContactFormMultiline
          key={fieldObj.label}
          fieldName={fieldName}
          currentFieldData={currentFieldData}
          setNewMessageData={setNewMessageData}
          errorMessage={errorMessages[fieldObj.label]}
        />
      );
    case INPUT_TYPES.picker:
      return (
        <ContactFormPicker
          key={fieldObj.label}
          fieldName={fieldName}
          currentFieldData={currentFieldData}
          options={fieldObj.options}
          setNewMessageData={setNewMessageData}
          errorMessage={errorMessages[fieldObj.label]}
        />
      );
    case INPUT_TYPES.checkbox:
      return (
        <ContactFormCheckbox
          key={fieldObj.label}
          fieldName={fieldName}
          currentFieldData={currentFieldData}
          text={fieldObj.text}
          setNewMessageData={setNewMessageData}
          errorMessage={errorMessages[fieldObj.label]}
          validationType={fieldObj.validationType}
        />
      );
    default:
      return null;
  }
};

const ContactFormFieldsFactory = ({ fieldsConfig, currentMessageData, setNewMessageData, errorMessages }) => {
  return (
    <>
      {fieldsConfig.map((fieldObj) =>
        getInputComponent(fieldObj, currentMessageData[fieldObj.label], setNewMessageData, errorMessages)
      )}
    </>
  );
};

const TYPE_FIELDS_CONFIG = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.any]),
});

ContactFormFieldsFactory.propTypes = {
  fieldsConfig: PropTypes.arrayOf(TYPE_FIELDS_CONFIG).isRequired,
  setNewMessageData: PropTypes.func.isRequired,
  errorMessages: PropTypes.objectOf(PropTypes.string).isRequired,
  currentMessageData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.any)])
  ).isRequired,
};

export default ContactFormFieldsFactory;
